<template>
  <div class="visual-template-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="模板名称：">
        <el-input v-model="searchForm.deviceTemplateNameLike" placeholder="请输入模板名称"></el-input>
      </el-form-item>
      <el-form-item label="模板ID：">
        <el-input v-model="searchForm.deviceTemplateCodeLike" placeholder="请输入模板ID"></el-input>
      </el-form-item>
      <el-form-item label="主/副柜：">
        <Select clearable v-model="searchForm.deviceMainSub" :list="deviceList"></Select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <div style="margin-top: 36px">
      <el-button type="success" size="small" @click="$router.push('/device-manage/visual-template/config')">新增</el-button>
    </div>
    <Table
      style="margin-top: 30px"
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
      <el-link
        slot="deviceTemplateCode"
        type="primary"
        slot-scope="{ originScope }"
        @click="handleClickTempId(originScope.row)"
      >
        {{ originScope.row.deviceTemplateCode }}
      </el-link>
      <template #deviceTemplateName="{ originScope }">
        <span class="g-single-overflow">{{ originScope.row.deviceTemplateName }}</span>
      </template>
      <template #deviceMainSub="{ originScope }">
        <span>{{ originScope.row.deviceMainSub === 1 ? '主柜' : '副柜' }}</span>
      </template>
      <template #cellStaticStr="{ originScope }">
        <span class="g-single-overflow">{{ originScope.row.cellStaticStr }}</span>
      </template>
      <template #productList="{ originScope }">
        <template v-if="originScope.row.productList.length">
          <div v-for="(item, index) in originScope.row.productList" :key="index">
            <span>{{ item.productCode }}</span>
            <el-divider direction="vertical"></el-divider>
            <span>{{ item.productName }}</span>
          </div>
        </template>
        <div v-else>-</div>
      </template>
      <template #deviceCellColumns="{ originScope }">
        <span>{{ originScope.row.deviceCellRows + '行' }},&nbsp;&nbsp;</span>
        <span>{{ originScope.row.deviceCellColumns + '列' }}</span>
      </template>
      <el-table-column slot="operate" label="操作" width="100" fixed="right">
        <template #default="scope">
          <el-button style="color: #77b922" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button style="color: #77b922" type="text" size="small" @click="deleteItem(scope.row)" v-if="scope.row.deviceTotal === 0">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <PublicDiaTc
      :title="title"
      :device-template-code="deviceTemplateCode"
      :visible.sync="diaDeviceVisible"
      :status="cabstatus"
    >
    </PublicDiaTc>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import Select from '@/components/Select'
import PublicDiaTc from '../../components/public-dia-tc'
import { searchWithStatic, deleteTemplate } from '@/api/modules/device'
export default {
  name: 'visualTemplate',
  components: {
    Table,
    Select,
    PublicDiaTc
  },
  data() {
    return {
      searchForm: {
        deviceTemplateNameLike: '',
        deviceTemplateCodeLike: '',
        deviceMainSub: ''
      },
      columns: [
        { label: '模板ID', prop: 'deviceTemplateCode' },
        { label: '模板名称', prop: 'deviceTemplateName', width: 240 },
        { label: '适用内部型号', prop: 'productList', 'min-width': 300 },
        { label: '主/副柜', prop: 'deviceMainSub' },
        { label: '行列数', prop: 'deviceCellColumns' },
        { label: '格口规格', prop: 'cellStaticStr' },
        { label: '关联设备数', prop: 'deviceTotal' },
        { label: '创建人', prop: 'createUser' },
        { label: '创建时间', prop: 'createSysTm', width: 160 }
      ],
      tableData: [],
      deviceList: [
        { value: 1, label: '主柜' },
        { value: 2, label: '副柜' }
      ],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      deviceTemplateCode: '',
      diaDeviceVisible: false,
      tableLoading: false,
      cabstatus: 1,
      title: ''
    }
  },
  created() {
    this.onSearch(true)
  },
  methods: {
    deleteItem(row) {
      deleteTemplate({
        deviceTemplateCode: row.deviceTemplateCode
      }).then(_ => {
        if (_.success) {
          this.$message.success('删除成功')
          this.onSearch(true)
        } else {
          this.$message.error(_.msg)
        }
      })
    },
    handleEdit(row) {
      this.$router.push({
        path: `/device-manage/visual-template/config?id=${row.deviceTemplateCode}`
      })
    },
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.searchForm = {
        deviceTemplateNameLike: '',
        deviceTemplateCodeLike: '',
        deviceMainSub: ''
      }
    },
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      searchWithStatic(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records
          this.pageParams = { pageNum, pageSize, total }
          console.log('searchWithStatic-res: ', res)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    // 处理模版id
    handleClickTempId(row) {
      // console.log('handleClickTempId: ', row)
      this.deviceTemplateCode = row.deviceTemplateCode
      this.diaDeviceVisible = true
      this.title = `${row.deviceTemplateName}(${row.deviceTemplateCode})`
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    changeDevice(value) {
      this.searchForm.deviceMainSub = value
    }
  }
}
</script>

<style lang="scss" scoped>
.visual-template-wrap {
  padding: 24px;
}
.el-link {
  font-weight: 400;
}
</style>
