<template>
  <div :style="cStyle">
    <el-select
      v-bind="$attrs"
      v-on="$listeners"
      class="width-multiple-select"
      :placeholder="placeholder"
      :value="value"
      @input="change($event)">
      <el-option
        v-for="item in list"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      default: 'name',
      type: String
    },
    id: {
      default: 'id',
      type: String
    },
    placeholder: {
      default: '请选择',
      type: String
    },
    value: {
      default: '',
      type: [String, Number, Array]
    },
    list: {
      default: () => {
        return []
      },
      type: Array
    },
    cStyle: {
      default: () => { return { width: '100%' } },
      type: Object
    }
  },
  methods: {
    change(val) {
      this.$emit('update:visible', val)
      setTimeout(() => {
        this.$parent.$parent.$parent.onSearch && this.$parent.$parent.$parent.onSearch()
      }, 100)
    }
  }
}
</script>

<style lang="scss">
 .width-multiple-select {
  width: 100%;
}
</style>
